.press-photos-ctr {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    justify-content: space-around;
    align-items: end;
    margin: 0 auto;
    margin-top: 20px;
}

.press-photo {
    margin: 4px;
    width: 40vw;
    height: auto;
    object-fit: contain;
}

.quotes-ctr {
    width: 80vw;
    margin: 0 auto;
}

.quote {
    text-align: justify;
}

.author {
    font-style: italic;
}

.img-ctr {
    position: relative;
    display: flex;
    flex-direction: column;
}

.photo-credit {
    position: absolute;
    margin: 4px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .8);
    padding: 0 8px;
}

#materialbox-overlay {
    background-color: rgba(0, 0, 0, .8);
}

@media screen and (max-width: 700px) {
    span {
        font-size: .8rem;
    }

    .photo-credit,
    .photo-credit>span {
        font-size: 1rem;
    }

    .quotes-ctr {
        width: 90vw;
        margin: 8px auto;
    }

}