.tmu-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tmu-main h1 {
    text-align: center;
}

.tmu-main h5 {
    text-align: center;
    padding: 0 24px 0 24px;
}

.tmu-project {
    box-shadow: 0px 0px 8px 1px var(--box-glow);
    /* border-radius: 16px; */
    border: 4px solid var(--blue-text);
    max-width: 90vw;
    position: relative;
}

.moon .iframe-ctr {
    max-width: 100%;
}