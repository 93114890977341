* {
  /* border: 1px solid red; */
}

.project-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-card {
  position: relative;
  width: 45%;
  margin: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 8px 1px var(--box-glow);
  /* border-radius: 16px; */
  border: 4px solid var(--blue-text);
  padding: 16px;
  background-color: var(--bpx);
  color: var(--blue-text);
  text-align: center;
  transition: 200ms
}


.project-card:hover,
.event-card:hover,
.t-event-card:hover {
  box-shadow: 0px 0px 32px 8px var(--box-glow);
  transition: 200ms
}

.project-cards-ctr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 24px;
}

.project-card img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* border-radius: 16px; */
}



.iframe-ctr {
  padding-bottom: 56.25%;
  position: relative;
  width: 100vw;
}


.iframe-ctr-card {
  border: none;
  width: 100%;
  position: relative;
  min-height: 23vw;
  display: flex;
  justify-content: center;
  background-color: var(--box);
}

.audio-iframe {
  padding: 0;
  margin: 20px;
  width: 50vw;
  height: 200px;
}

.secondary-iframe-ctr {
  padding-bottom: 50%;
  position: relative;
  width: 90%;
}

.iframe-load-flower {
  max-width: 33%;
  position: absolute;
  top: 16%;
  left: 33%;
  animation: rotate-dance 3s linear infinite;
}

.project-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
}

.page-gif {
  width: 25vw;
}

.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-name {
  text-align: center;
  font-size: var(--medium);
  margin: 0;
}

.project-desc {
  text-align: justify;
  width: 80%;
}

.project-card-desc {
  font-size: var(--small);
}

.secondary-caption {
  text-align: center;
}

.gif-reverse {
  transform: rotateY(180deg);
}

.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}


.project-category {
  padding-bottom: 6px;
}



.click-for-more {
  font-size: var(--very-small);
  padding: 0;
  margin: 0;
}

@keyframes rotate-dance {
  0% {
    rotate: 0;
  }

  100% {
    rotate: 359deg
  }
}

@media screen and (max-width: 700px) {

  .project-info {
    text-align: justify;
  }

  .project-category {
    font-size: var(--large);
  }

  .project-card {
    width: 90%;
  }

  .page-gif {
    width: 25%;
  }

  .iframe-ctr-card {
    min-height: 48vw;
  }

  .audio-iframe {
    width: 99vw;
    height: 200px;
  }

}