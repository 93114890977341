.events-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 0;
}

.event-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events-body>.img-ctr>img {
    width: 100vw;
    margin: 0;
}

.event-page-text,
.event-page-program-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small);
}


.event-page-program-intermission {
    font-style: italic;
}

.event-page-program-line {
    color: var(--blue-text);
    border-radius: 12px;
    padding: 2px 8px;
    margin: 4px;

}

.event-page-program-line:hover {
    color: var(--bg);
    background-color: var(--blue-text);
    box-shadow: 0 0 10px 2px var(--box-glow);
    transition: 300ms;
}

.event-page-text a {
    width: fit-content;
    margin: 0 auto;
}

.video-caption {
    margin-top: 4px;
    font-size: var(--small);
}

.event-desc {
    width: 80vw;
    text-align: justify;
}

.event-card {
    width: 75%;
    margin: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 8px 1px var(--box-glow);
    /* border-radius: 16px; */
    border: 1px solid var(--blue-text);
    padding: 16px;
    background-color: var(--box);
    color: var(--blue-text);
    text-align: center;
    transition: 200ms;
    position: relative
}

.event-card-title {
    font-size: var(--large);
}

.event-card-desc {
    width: 75%;
    padding: 50px;
    font-size: var(--medium);
    margin: 0 auto;
}

.event-card-location {
    margin-top: 0;
}

.event-card-l {
    max-width: 33%;
    position: relative;
}

.event-card-series {
    margin-top: 2px;
    font-size: var(--small);
}

.event-card-presenter {
    font-size: var(--medium);
}

.event-card-l img {
    width: 100%;
}

.event-photos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

@media screen and (max-width: 1100px) {
    .event-card-desc {
        width: 90%;
        padding: 4px;
    }


}

@media screen and (max-width: 700px) {
    .event-card {
        flex-direction: column;
        position: relative;
        width: 90%;
    }

    .event-card-desc {
        width: 100%;
        padding: 0;
        font-size: var(--small)
    }

    .event-card-date,
    .event-card-location {
        font-size: var(--very-small)
    }

    .event-card-l {
        min-width: 100%;
        text-align: center;
        margin: 0 auto
    }
}