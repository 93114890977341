body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg: #15161a;
  --blue-text: rgb(188, 173, 230);
  --nav-bar: rgb(32, 32, 41);
  --scroll-bar: #26265a;
  --box-glow: #464185;
  --box: #1f2033;
  --rainbow-1: #8d93ba;
  --rainbow-2: #9494dc;
  --rainbow-3: #746cdd;
  --rainbow-4: #5e49e1;
  --rainbow-5: #703eea;
  --rainbow-6: #933fea;
  --rainbow-7: #9f7be2;
  --very-small: 1.25rem;
  --small: 1.5rem;
  --medium: 2rem;
  --large: 3rem;
  --very-large: 4rem;
}

.center-text {
  text-align: center;
}