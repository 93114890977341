.tranzac-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tranzac-p {
    text-align: center;
    padding: 0 6vw;
}

.tranzac-event-line{
    color: var(--blue-text);

}



.tranzac-event-line:hover{
    color: var(--bg);
    background-color: var(--blue-text);
    box-shadow: 0 0 10px 2px var(--box-glow);
    transition: 300ms;
}

.t-event-card {
    width: 75%;
    margin: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 8px 1px var(--box-glow);
    /* border-radius: 16px; */
    border: 1px solid var(--blue-text);
    padding: 16px;
    background-color: var(--box);
    color: var(--blue-text);
    text-align: center;
    transition: 200ms;
    position: relative
}


.t-event-card-middle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.t-event-card-l{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
