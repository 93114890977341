.bio-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bio-body * {
    /* border: 1px solid red; */
}


.bio-body img {
    width: 100%;
}

.bio-body .press-photo {
    margin: 0;
}

.bio-name {
    font-size: var(--large);
}

.bio {
    padding: 0 10%;
    font-size: var(--medium);
    text-align: justify;
}


.bio-body a {
    color: var(--blue-text);
    padding: 0;
    margin-top: 32px;
}

.overlay-ctr {
    position: relative;
    width: 35vw;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.about-photo-arrow {
    max-width: 70px;
    cursor: pointer;
}

.arrow-l{
    rotate: 180deg;
}


.overlay-img {
    height: 100%;
    width: 100%;
    padding: 0 70px;
    position: absolute;
    pointer-events: none !important;
}
@media screen and (max-width: 1100px) {

    .overlay-ctr {
        width: 45vw;
    }
}

@media screen and (max-width: 700px) {

    .about .bio-name {
        font-size: 3rem;
    }

    .bio {
        font-size: var(--small);
    }

    .overlay-ctr {
        width: 75vw;
    }
}





@keyframes left-slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes right-slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

/* You might want to add the reverse animations as well */
@keyframes right-slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes left-slide-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-out {
    animation: left-slide-out 0.3s forwards;
}

.slide-in {
    animation: right-slide-in 0.3s forwards;
}

/* And for the reverse animations */
.slide-out-reverse {
    animation: right-slide-out 0.3s forwards;
}

.slide-in-reverse {
    animation: left-slide-in 0.3s forwards;
}