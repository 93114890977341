.hero {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.carousel {
    min-height: 56vw;
    /* border: 1px solid red; */
}

.carousel-item {
    min-width: 100vw;
    min-height: 56vw;
    /* border: 1px solid red; */
    position: relative;
    
}

.home-bio {
    width: 80vw;
    margin: 16px auto;
    text-align: justify;
    font-size: 32px;
}

span.bio {
    font-size: var(--large)
}

hr {
    width: 85%;
    margin-top: 32px;
}



form,
.contact-success-ctr {
    background-color: var(--box);
    border-radius: 4px;
    padding: 16px;
}

.contact-success-ctr {
    color: var(--blue-text);
    width: 75vw;
    margin: 0 auto;
}

.contact-form-ctr {
    width: 75vw;
    margin: 0 auto;
    border: 1px solid white;
    padding: 16px;
}


label {
    color: var(--blue-text);
    font-size: var(--very-small);
}


@media screen and (max-width: 700px) {
    .hero {
        justify-content: start;
    }

    .carousel {
        max-height: 30px;
    }

    .home-bio {
        font-size: var(--small);
    }

    span.bio {
        font-size: var(--medium)
    }


    .contact-form-ctr {
        border-radius: 24px;
        border: none;
        padding: 0;
        width: 90vw;
    }

}