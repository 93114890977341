.sylvan-entry {
    box-shadow: 0px 0px 8px 1px var(--box-glow);
    border: 4px solid var(--blue-text);
    position: relative;
    padding: 16px 0;
    width: 80vw;
    left: 10vw;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.funders-imgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    left: 0;
}

#game-iframe {
    min-height: 39vw;
    max-height: 39vw;
    max-width: 100%;
    position: relative;
}

.sylvan-iframe-ctr {
    width: 100%;
}

.sylvan-images-div {
    box-shadow: 0px 0px 8px 1px var(--box-glow);
    border: 4px solid var(--blue-text);
    position: relative;
    padding: 16px 0;
    width: 80vw;
    left: 10vw;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}

.sylvan-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 8px;

}

.sylvan-images>img {
    width: 40%;
    padding: 8px;
}