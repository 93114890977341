.my-footer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    width: 100vw;
    position: relative;
    min-height: 150px;
}

.footer-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.footer-text {
    position: absolute;
    right: 24px;
    bottom: 0;
}

.socials-ctr img {
    width: 70px;
    margin: 0 8px;
}

.social-flower{
    position: absolute;
    opacity: 0;
    min-width: 140px;
    top: -70px;
    margin: -20px;
}

.social-flower:hover{
    opacity: 1;
    transition: 400ms;
}

.social-l{
    left: 0;
}

.socials-ctr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    position: relative;
}

.subscribe-text {
    color: var(--blue-text);
    font-size: var(--very-small);
}


.mail-form-ctr {
    padding: 8px;

}


.mail-form-ctr {
    width: 380px;
}

.mail-form-ctr * {
    text-align: center;
}

#mce-EMAIL {
    width: 50%;
    display: inline;
    box-shadow: none;
    border-color: var(--blue-text) !important;
}


#mc-embedded-subscribe {
    width: 30%;
    margin: 0 10%;
    display: inline;
}

@media screen and (max-width: 700px) {

    .mail-form-ctr {
        width: 90vw;
    }


    .footer-text {
        position: relative;
        right: 0;
    }

    .socials-ctr img {
        width: 24px;
    }

}