@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: Jazfont-Regular;
  src: url("./styles/fonts/Jazfont-RegularOTF.otf") format("opentype"),
    url("./styles/fonts/Jazfont-RegularTTF.ttf") format("truetype");
}

* {
  /* border: 1px solid red; */
  text-decoration: none;
}

html,
body {
  max-width: 100%;
  height: 100vh;
  color: var(--blue-text);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

a {
  text-decoration: none;
  color: none;
}

.white {
  color: white;
}

.no-width {
  opacity: 0
}

button {
  font-family: "Jazfont-Regular", serif !important;
  cursor: pointer;
  transition: 200ms;
  color: var(--blue-text);
  background-color: var(--bg);
  border-radius: 12px;
  border: 1px solid white;
  padding: 4px 8px;
  font-size: var(--small) !important;

}

button:hover {
  transition: 200ms;
  color: var(--bg);
  background-color: var(--blue-text);
  box-shadow: 0px 0px 24px 1px var(--box-glow);
}

button:focus {
  background-color: black !important;
  color: white !important;
}

.italic {
  font-style: italic;
}

input {
  color: var(--blue-text);
}

input::placeholder {
  color: var(--blue-text);
  font-family: "Jazfont-Regular", serif !important;

}

.myh3 {
  font-size: 1.25rem;
}

.nopacity {
  opacity: 0;
}

.loading-title {
  text-align: center;
}

.margin-auto {
  margin: 0 auto;
}

h1 {
  font-size: 1.75em;
}

.App {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Jazfont-Regular", serif;
  position: relative;
}

footer {
  position: relative;
}

header {
  position: absolute;
}

iframe {
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  position: absolute;
  border: none;
}

.name {
  color: transparent;
  width: fit-content;
  background-color: white;
  background-size: contain;
  background-clip: text;
}

.about-name {
  display: none;
}

.about-main {
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-top: 90px;
  overflow: hidden;
  justify-content: space-between;
  transition: 1000ms ease-in-out
}

.bio-text {
  font-size: 1rem;
}

h2 {
  font-size: 48px;
}

.font700 {
  font-size: var(--medium)
}

.link {
  transition: font-weight 100ms;

}

.link:hover {
  font-weight: 700;
  color: transparent;
  transition: font-weight 100ms;
  /* background: linear-gradient(90deg,
      var(--rainbow-1),
      var(--rainbow-2),
      var(--rainbow-4),
      var(--rainbow-5),
      var(--rainbow-6),
      var(--rainbow-7),
      var(--rainbow-6),
      var(--rainbow-5),
      var(--rainbow-4),
      var(--rainbow-3),
      var(--rainbow-2));
  animation: animate 5s linear infinite; */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  /* background-size: 200% 100%; */
  background-image: url("https://res.cloudinary.com/dsvcyich1/image/upload/v1716498200/TextBG_xbus5x.jpg");
}


.works:hover {
  color: transparent;
  text-decoration: none;
  background: #ef76a2;
  animation: 15s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
}

.glow,
.hover-glow:hover {
  /* background: linear-gradient(90deg,
      var(--rainbow-1),
      var(--rainbow-2),
      var(--rainbow-4),
      var(--rainbow-5),
      var(--rainbow-6),
      var(--rainbow-7),
      var(--rainbow-6),
      var(--rainbow-5),
      var(--rainbow-4),
      var(--rainbow-3),
      var(--rainbow-2)); */
  background-image: url("https://res.cloudinary.com/dsvcyich1/image/upload/v1716498200/TextBG_xbus5x.jpg");
  /* animation: animate 5s linear infinite; */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  /* background-size: 200% 100%; */
  position: relative;
}

.glow:after {
  /* content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%;
  background: linear-gradient(180deg,
      var(--rainbow-1),
      var(--rainbow-2),
      var(--rainbow-4),
      var(--rainbow-5),
      var(--rainbow-6),
      var(--rainbow-7),
      var(--rainbow-6),
      var(--rainbow-5),
      var(--rainbow-4),
      var(--rainbow-3),
      var(--rainbow-2)); 
  filter: blur(100px);*/
}


._404 {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //////////////////////////////////////////////////////////////////////Flower Kaboom/////////// */


.kaboom {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  visibility: visible;
  z-index: 1;
}

.spin {
  animation: spin .5s ease-in-out;
}

.flower0 {
  background-image: url("../public/assets/ChicoryTrans.PNG");
}

.flower1 {
  background-image: url("../public/assets/Chicory4spin.PNG");
}

.flower2 {
  background-image: url("../public/assets/ChicoryKaboom.png");
}

#kaboom-ctl0,
#kaboom-ctl1,
#kaboom-ctl2 {
  display: none;
  z-index: 10;
}

/* #kaboom-ctl0:checked + label > .kaboom-ctr0 {
  background-image: url("../public/assets/kaboom.gif");
  animation: hideKaboom .1s linear forwards;
  animation-delay: .75s; 
}
#kaboom-ctl1:checked + label > .kaboom-ctr1 {
  background-image: url("../public/assets/kaboom.gif");
  animation: hideKaboom .1s linear forwards;
  animation-delay: .75s; 
}
#kaboom-ctl2:checked + label > .kaboom-ctr2 {
  background-image: url("../public/assets/kaboom.gif");
  animation: hideKaboom .1s linear forwards;
  animation-delay: .75s; 
} */

@keyframes hideKaboom {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}


/* //////////////////////////////////////////////////////////////////////Scroll Bar/////////// */
#progressbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 12px;
  z-index: 10;
  min-height: 100vh;
  background: var(--scroll-bar);
}

.scroll-img {
  max-width: 200%;
  position: absolute;
  z-index: 0;
  left: -8px
}


@keyframes animate {
  0% {
    background-position: 200% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 359deg;
  }
}


.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

@media screen and (max-width: 1000px) {
  .about-main {
    flex-direction: column;
    margin-top: 70px;
  }

  .about-name {
    display: block;
    text-align: center;
    line-height: 80px;
  }
}

@media screen and (max-width: 700px) {

  #progressbar {
    width: 2px;
  }

  input {
    font-size: .8rem;
  }


}

@media screen and (max-width: 450px) {

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .glow:after {
    background: none;
  }

}