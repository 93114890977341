header {
    width: 100vw;
    height: 130px;
    position: relative;
    background-color: var(--nav-bar);
    padding: 0;
    /* border: 1px solid red; */
}


* {
    /* border: 1px solid red; */
}

.header-content {
    padding: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.cic-logo {
    min-height: 130px;
    min-width: 420px;
    padding: 0;
    background: url("../../public/assets/BannerNoFlowersTrans2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}


.cic-logo-flowers{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 15;
    opacity: 0;
    background: url("../../public/assets/LogoBloomFinal.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

} 

.cic-logo-flowers-gif{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 15;
    opacity: 0;
    background: url("../../public/assets/Logo_Gif.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} 

.cic-logo-flowers-gif-reverse{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    opacity: 0;
    background: url("../../public/assets/LogoBloomReverse.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} 

.my-nav {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    background-color: none !important;
    width: 40%;
    padding: 0;
    margin: 0;
}

.nav-l {
    padding-left: 8px;
    align-items: left;
}
.nav-l .nav-btn-text{
    text-align: left;
}
.nav-r .nav-btn-text{
    text-align: right;
}
.nav-r {
    padding-right: 24px;
    align-items: end;
}

.nav-btn-text {
    display: inline-block;
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    color: var(--blue-text);
    padding: 0;
    margin: 0;
}

.current-location::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: var(--rainbow-7);
}

.nav-l .nav-btn-text::after {
    left: 0;
}

.nav-r .nav-btn-text::after {

    right: 0;
}


.nav-btn-text:hover::after {
    transition: 0.1s ease;
    width: 100%;
}

.nav-btn-text:hover {
    transition: 0.1s ease;
}

.Link {
    text-decoration: none;
    text-align: center;
    height: fit-content;
    margin: 0;
    padding: 0;
    border: none;
}


.hamburger {
    display: none;
    width: 36px;
    height: auto;
    margin: 8px;
    position: fixed;
    right: 0;
    z-index: 15;
}

#hamburger-div {
    position: fixed;
    margin: 0 auto;
    z-index: 15;
    right: 0;
    /* border: 1px solid red; */
    width: 100%;
}

.dropdown-hidden {
    display: none;
}

.dropdown-visible {
    display: block;
}


.dropbtn {
    position: relative;
    display: block;
    z-index: 20;
}

.my-dropdown-content,
.work-content {
    position: absolute;
    background: rgba(0, 0, 0, .9);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--blue-text);
}


.work-content {
    right: 0;
    width: 150px;
}


.workbtn {
    position: relative;
    cursor: pointer;
}


.my-dropdown-content {
    animation: burger-drop 300ms ease-in-out;
    width: 100vw;
    height: 100vh;
}


.burger-link-text {
    display: block;
    padding: 0 16px;
    line-height: 1.25rem;
    margin: 15% 0;
    text-align: center;
    color: var(--blue-text);
    font-size: 18px;
    font-weight: bold;
    z-index: 20;

}

.work-link-text {
    font-size: 16px;
    color: var(--blue-text);
    padding: 2px 4px;
    width: 100%;
    text-align: center;
    z-index: 20;

}


@keyframes burger-drop {
    0% {
        right: -100vw;
    }

    100% {
        right: 0;
    }
}



@media screen and (max-width: 750px) {
    .nav-btn-text {
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 600px) {
    .hamburger {
        display: block;
    }

    .header-content * {
        display: none;
    }

    header {
        background-color: transparent;
        height: 0px;
    }
}